@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.maxValueLine {
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0 0 1rem;
  align-items: center;

  &.alignGap {
    margin-top: -0.5rem;
  }
}

.inputWithTooltip {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
}

.maxValueInput {
  width: 100%;
}
