@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins';

.container {
  width: 100%;
  height: 2rem;
  margin-bottom: 0.5rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  @include lg {
    height: 1.25rem;
  }
}
