@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.carouselSkeletonWrapper {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.carouselSkeletonMain {
  display: flex;
  gap: 1.875rem;
  height: 39.3125rem;
  align-items: center;
}

.carouselSkeletonArrow {
  background: $darkGray21K;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  border-radius: 0.1875rem;
}

.carouselSkeletonNavigation {
  width: 4.5rem;
  height: 0.5rem;
  border-radius: 0.1875rem;
  align-self: center;
  background: $darkGray21K;
}

.carouselSkeletonCard {
  background: $darkGray21K;
  width: 100%;
  height: 100%;
}
