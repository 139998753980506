@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.outerGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: $white;
  box-sizing: unset;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  box-shadow: 0 5px 5px $black15pct, 0 -5px 5px $black15pct;
  border: none;
  width: 40.625rem;
  padding: 2rem;
  @include md {
    padding: 2.5rem;
  }
  gap: 1rem;
}

.headerBox {
  width: 100%;
}
.heading {
  padding-bottom: 1rem;
}
.heading,
.optionGrid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.fullHeight {
  height: 100%;
}

.footerGrid {
  display: flex;
  width: 100%;
  justify-content: right;
}

.radioGroup {
  :global .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
  }
  :global .MuiRadio-root {
    padding: 0 0.5rem 0 0;
  }
}
.formControl {
  row-gap: 1rem;
}

.dividerWrapper {
  width: 100%;
}

.iconStyling {
  width: 1.5rem;
  height: 1.5rem;
  svg {
    margin-top: 0.125rem;
    margin-left: 0.125rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.skeleton,
.skeletonTitle {
  border-radius: 0.25rem;
}

.skeleton {
  width: 100%;
  height: 11.75rem;
}

.skeletonTitle {
  width: 20.813rem;
}
