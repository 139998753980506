@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.recommendationsPanel {
  padding-top: 2rem;

  .topSection {
    margin-bottom: 1rem;

    .titleWrapper {
      margin-bottom: 1rem;
    }
  }
}

.cardWrapper {
  &:global(.swiper-slide) {
    //we target the swiper-slide directly in order to have a higher specifity against the default styling
    position: relative;
    width: 16rem;
    padding: 5px;
  }
}

.recommendationsPanelSkeletonContent {
  height: 33.625rem;
}
