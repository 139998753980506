@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.wrapper {
  background: $white;
  padding: 1rem;
  border-radius: 4px;

  .divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-color: $darkGray34K;
  }

  .sectionTitleWrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .underlineText {
      text-decoration: underline;
      text-underline-offset: 3px;
    }

    .tooltipIcon {
      height: 1rem;
      width: 1rem;
    }
  }
}
