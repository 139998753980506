@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/elevations.scss';

.root {
  gap: 1rem;
  display: flex;
  flex-direction: column;

  .firmDataSection,
  .priceSection {
    padding: 1rem;
    background: $white;
    border-radius: 4px;
    @include dropShadow1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
