@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.header {
  .sectionTitleWrapper {
    text-align: start;
    margin-bottom: 1rem;
  }

  .text {
    text-align: start;
  }
}

.toggler {
  padding-bottom: 0.5rem;

  .divider {
    margin-bottom: 1rem;
    border-color: $darkGray34K;
  }
}
