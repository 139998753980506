@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.root {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  @include articleDetailLeftRightPadding;
}

.data {
  min-width: 0;
  max-width: 100%;
  grid-template-columns: 1fr;
}

.comparableItemsButton {
  width: fit-content;
  justify-self: flex-end;
  @include md {
    justify-self: flex-end;
  }
}

.checkbox {
  transform: scale(1);
}

.label {
  font: inherit;
}

.showLessIcon {
  transform: rotate(180deg);
}
.btn-wrapper {
  width: fit-content;
  justify-self: flex-end;
  @include below-sm {
    margin-top: 1rem;
    justify-self: center;
  }
}

.techDataCellClassName {
  padding: 0.5rem 0 0.5rem 1rem;

  &:last-child {
    padding-right: 1rem;
  }

  @include below-lg {
    padding: 0.875rem 0 0.875rem 1rem;
  }

  @include below-sm {
    padding: 0.5rem 0 0.5rem 1rem;
  }
}

.techDataSelectedCheckbox {
  padding: 0;
}

.featureTextWithValueWrapper {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  width: 100%;

  .title,
  .value {
    overflow-wrap: break-word;
  }
}
