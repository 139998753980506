@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.activeForm {
  margin-left: 1.5rem;

  &.touchWrapper {
    margin-left: 2rem;
    margin-top: 0.5rem;
  }
}

.nestedMailContainer {
  display: flex;
  flex-direction: column;
  margin-left: 2.75rem;

  &.touchContainer {
    margin-left: 3.75rem;
  }
}

.nestedMailTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0;
}

.nestedMailInfo {
  margin-top: 0.25rem;
  color: $darkGray55K;
}
