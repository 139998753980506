@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.wrapper {
  padding-left: 1.725rem;
  display: flex;
  flex-direction: column;

  @include below-lg {
    padding-left: 2.5rem;
  }

  @include below-md {
    padding-left: 0;
  }

  .offerPriceSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .itemBox {
    padding: 0 1.5rem 0.5rem 0;
    display: flex;
    gap: 0.5rem;

    @include below-sm {
      padding: 0;
    }
  }

  .inputRoot {
    max-width: 7.1875rem;
  }

  .input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      opacity: 1;
      color: $darkGray55K;
      @include bodyBoldFont;
    }
  }
}
