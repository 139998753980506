@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card {
  background: $white;
  padding: 1rem;
  border-radius: 4px;
}

.infoText {
  font-size: 1rem;
}

.formFields {
  display: flex;
  align-items: center;
  width: 100%;
}

.emailInput {
  display: flex;
  flex-grow: 1;
}
