@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.button {
  align-items: center;
  background-color: $red100pct;
  border-radius: 0 0.25rem 0.25rem 0;
  color: $white;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 2.5rem;
  justify-content: center;
  min-width: 0;
  padding: 0.5rem;
  width: 2.5rem;
  &:active {
    background-color: $red89pct;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.dropdownPaper {
  padding-left: 0;
  @include sm {
    padding: 0 0.75rem;
  }
}

.inputContainer {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  height: 100%;
  input {
    height: 2.5rem;
  }
}

.searchFieldWithOptions {
  align-items: center;
  display: flex;
  width: 100%;

  :global .MuiTextField-root {
    height: 100%;
  }
}
