@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .itemBox {
    padding: 0.375rem 0;

    .underlineText {
      text-decoration: underline;
      text-underline-offset: 3px;
    }

    &.withTooltip {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }

    .tooltipIcon {
      height: 1rem;
      width: 1rem;
    }

    @include below-lg {
      padding: 0;
    }
  }

  .itemBoxSmallPadding {
    padding: 0.125rem 0;
    display: flex;
    gap: 0.5rem;
  }

  .percentagePriceBaseValue {
    @include below-sm {
      flex-wrap: wrap;
      padding: 0 0 0.5rem;
    }
  }

  .percentagePriceBaseValueRow {
    display: flex;
    gap: 0.5rem;

    @include below-sm {
      width: 100%;
    }

    .percentagePriceBaseValuePlaceholder {
      letter-spacing: normal;
    }

    .percentagePriceBaseValueSelect {
      width: 16rem;
    }
  }
}

.pricePercentageMenuItem {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.priceMarkupWrapper {
  width: 11rem;
  @include below-sm {
    margin-left: 2.5rem;
    width: 16rem;
  }
}

.inputNumber {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    opacity: 1;
    @include bodyBoldFont;
  }
}
