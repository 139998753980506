@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.subtitle {
  margin-bottom: 0.5rem;
}

.navMenu {
  margin-bottom: 1.5rem;
}

.menuItems {
  .menuButton {
    width: auto;
    padding-right: 1.5rem;
  }
}

.submenuItem {
  gap: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 2.25rem;
}

.submenuBack {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 1rem;
}

.submenuPopoverMobile {
  height: 100%;
  padding: calc(1.5rem + var(--obeta-safe-area-top)) 0.5rem
    calc(1.5rem + var(--obeta-safe-area-bottom)) 0.5rem !important;
}
