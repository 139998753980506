@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.notifySubFormContainer {
  display: flex;
  flex-direction: column;
  margin-left: 2.75rem;

  &.touchContainer {
    margin-left: 3.75rem;
  }
}

.notifySubFormTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0;
}

.notifySubFormInfo {
  margin-top: 0.25rem;
  color: $darkGray55K;
}
