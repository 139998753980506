@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'assets/theme/mixins.scss';

.content {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sectionFormWrapperStyles {
  border: 1px solid #bebebe;
}

.formActionFooter {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  gap: 1.5rem;

  @include below-sm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding-top: 0.5rem;
  }
}

.uploadLogoSectionTitle {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.uploadLogoSectionIcon {
  width: 1rem;
  height: 1rem;
}

.timeFormField {
  display: grid;
  grid-template-columns: 4.5rem 1fr 1.125rem 1fr;
  column-gap: 0.5rem;
  align-items: center;
}
