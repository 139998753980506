@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.card {
  .content {
    padding: 1rem;
  }

  .bottom {
    padding-top: 0.875rem;
  }
}

.name {
  margin-top: 0.5rem;

  &:global(.MuiGrid-item) {
    max-width: 100%;
  }

  *:hover {
    text-decoration: none;
  }
}
