@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.tableWithBorder {
  overflow: hidden;
  border-radius: 0.25rem;
  border: 0.0625rem solid $lightGray;
}

.body {
  .row:nth-child(odd) {
    background-color: $lightGray5K;
  }
  .row:nth-child(even) {
    background-color: #fff;
  }

  .rowWithBorders {
    display: flex;
    align-items: flex-start;
    // We use the box-shadow approach to add a border instead of removing the top or bottom border due to page breaks
    box-shadow: 0 0 0 1px $lightGray;
  }

  .firstRow {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .lastRow {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}

.cell {
  flex: 1;
  padding: 0.625rem;
  color: $darkGray;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
