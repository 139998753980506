@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/mixins.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 4rem 4rem;
  gap: 2rem;
  background-color: $white;
  border-radius: 0.5rem;
  @include dropShadow1;
  border: none;
  color: $darkGray;
  width: 64.875rem;
  margin-left: auto;
  margin-right: auto;
  @include below-lg {
    width: 100%;
  }
}

.textBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.headlines,
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.body {
  align-self: stretch;
  flex-grow: 0;
  text-align: center;
}

.contactInformationRow {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}
