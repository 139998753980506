@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/mixins.scss';

.shippingGroupOptions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  @include below-md {
    gap: 1.5rem;
  }
  @include below-sm {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  gap: 2rem;
}

.shippingGroupOption {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 1.5rem;
  @include below-md {
    padding: 1rem;
  }

  :global .MuiRadio-root {
    padding: 0;
  }
}

.selected {
  border: 1px solid $primaryRed;
  box-shadow: 0 0 15px 0 $black15pct;
}

.shippingGroupOption:not(.disabled):hover {
  box-shadow: 0 0 15px 0 $black15pct;
}
