@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
.radioContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 0.75rem;
}

.titleWithoutCheckbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
